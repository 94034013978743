<template>
  <div class="home">
    威趣科技(深圳)有限公司-CDN
  </div>
</template>

<script>

export default {
  name: "Home",
};
</script>

<style lang="scss" scoped>
.home {
  text-align: center;
  margin-top: 200px;
  font-size: 38px;
}
</style>
